import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { getStorageRefreshToken, getStorageToken, getStorageUserDetail, removeAllUserData, setStorageRefreshToken, setStorageToken } from "../utils/storage";

import { USER_ROLE } from "../utils/constants";


const AuthPrivateRoute = ({}) => {

    const token = getStorageToken();
    const currentUser = getStorageUserDetail();

  useEffect(() => {
    const trackingScriptTag = document.createElement("script");
    trackingScriptTag.id = "trackingScriptTag";
    trackingScriptTag.innerHTML = `
      (function(c,l,a,r,i,t,y){ c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)}; t=l.createElement(r);t.async=1;t.src=" https://www.clarity.ms/tag/"+i; y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y); })(window, document, "clarity", "script", "iwxej5ixib");
      `;
    document.body.append(trackingScriptTag);

      return () => {
        const tag = document.getElementById('trackingScriptTag')
        if(tag) {
          tag.remove()
        }
      }
    }, [])

    return <>
    
    {token && currentUser ? currentUser?.role === USER_ROLE.CUSTOMER ? <Navigate to="/dashboard/home" /> : <Navigate to="/dashboard/home" /> : <Outlet />}
    {/* {!!!currentUser?.isOnboarding && <Onboarding />} */}
  </>
}

export default AuthPrivateRoute;
