import { useState, useEffect } from 'react';
import axios from '../api/base.api';
import _ from 'lodash';

const AnalyticsApiHelper = ({url, method, body,setCheckComponentClick}) => {
    const [apiData, setApiData] = useState(null);
    const [isSuccess, setIsSuccess] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [errorText, setErrorText] = useState("");


    
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                let response;
                if (method === 'post') {
                    response = await axios.post(url, body);
                    if(response.status){
                        console.log(response.data)
                    }
                } else {
                    response = await axios.get(url);
                }
                setApiData(response.data);
                setIsSuccess(true);
                setErrorText("");
            } catch (err) {
                setApiData(null);
                setIsSuccess(false);
                setErrorText(_.get(err, ['response', 'data', 'message'], 'Error!'));
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();

        return(()=>{
            setCheckComponentClick(false);
        })
    }, []);

    return (<>

    </>);
};

export default AnalyticsApiHelper;
