import { useEffect, useState } from "react";
import authApi from "../../../api/auth.api";
import { useNavigate } from "react-router-dom";
import routesConstant from "../../../routes/routesConstant";
import AuthBackground from "../../../assets/images/AuthBackground.png";
import LOGO from "../../../assets/images/LOGO.svg";
import RetailerAuthFooter from "../../../components/retailerComponents/retailerAuthFooter/RetailerAuthFooter";
import { decodeUrl, getDefaultHomePage } from "../../../utils/util";
import { userApi } from "../../../api/user.api";
import {
  setStorageRefreshToken,
  setStorageToken,
  setStorageUserDetail,
  setUserAnalytics,
} from "../../../utils/storage";
import { setInitializedUser, setUser } from "../../../redux/appSlice";
import { useDispatch } from "react-redux";
import { notification } from "antd";
import { USER_ROLE } from "../../../utils/constants";

const VerifyEmail = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [isVerifiedSuccess, setIsVerifiedSuccess] = useState(false);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [failedComponent, setFailedComponent] = useState(false);
  const dispatch = useDispatch();
  // const [items, setItems] = useState([
  //     {
  //         id: 1,
  //         background: BackGround1
  //     },
  //     {
  //         id: 2,
  //         background: BackGround2
  //     },
  //     {
  //         id: 3,
  //         background: BackGround3
  //     }
  // ])
  const [count, setCount] = useState(5);
  useEffect(() => {
    const token = new URLSearchParams(window.location.search).get("token");
    if (!token) {
      navigate(routesConstant.login.path);
    }

    authApi
      .verifyEmail(token)
      .then((rs) => {
        console.log(rs, "response from verify Email component");
        console.log(rs.user.email, "ccccccccccccccccccccc");

        setEmail(rs.user.email);
        if (!rs.user.email) {
            console.log("stateeeeee not updateddddddddd");
            
          setFailedComponent(true);
        }
        setName(rs?.user?.name);
        setLoading(false);
        setIsVerifiedSuccess(true);
        startCountdown();

        const formData = { userId: rs.user.id, device_type: getDeviceType() };
        userApi
          .initializeUser(formData)
          .then((response) => {
            if (response.status) {
              dispatch(setInitializedUser(response.data));
              setUserAnalytics(response.data);
            } else {
              notification.error({ message: response.data.message });
            }
          })
          .catch((error) => {
            notification.error({ message: "Failed to initialize the user" });
          });

        if (rs.user) {
          console.log("User data is being set");

          setStorageToken(rs.tokens.access.token);
          setStorageRefreshToken(rs.tokens.refresh.token);
          // Delay navigation by 5 seconds
          setTimeout(() => {
            setStorageUserDetail(rs.user);
            dispatch(setUser(rs.user));
            const returnUrl = new URLSearchParams(window.location.search).get(
              "returnUrl"
            );
            console.log(returnUrl, "return url ssssssssssssssssssssssss");

            if (returnUrl) {
              window.location = decodeUrl(returnUrl);
            } else {
              notification.success({ message: "Register success" });
              if (rs.user?.role === USER_ROLE.RETAILERS) {
                navigate(routesConstant.firstLogin.path);
              } else {
                navigate(getDefaultHomePage(rs.user));
              }
              // navigate(getDefaultHomePage(rs.user, true));
            }
          }, 5000); // 5 seconds delay
        }
      })
      .catch((err) => {
        setEmail(null);
        setIsVerifiedSuccess(false);
        setLoading(false);
      });
  }, []);
  const getDeviceType = () => {
    const width = window.innerWidth;
    if (width <= 768) return "Mobile";
    else if (width <= 1024) return "Tablet";
    return "Desktop";
  };
  const startCountdown = () => {
    const countdown = (value) => {
      if (value >= 0) {
        setCount(value); // Update the counter
        setTimeout(() => {
          countdown(value - 1); // Decrease the value and call again
        }, 1000); // Call every 1 second
      }
    };

    countdown(count); // Start the countdown from the current counter value
  };

  return (
    <>
      {/* <div className='px-[32px] md:px-[73px] min-h-screen flex justify-center items-center w-full relative'>
            {
                items.map((el, idx) => (
                    <div
                        className={`login-image-wrapper ${index % items.length === idx ? 'active' : ''}`}
                        key={el.id}
                        style={{
                            backgroundImage: `url(${el.background})`
                        }}
                    >
                    </div>
                ))
            }
            <Row className='login__container rounded-[10px] md:rounded-[20px] overflow-hidden w-full min-h-[90vh] relative z-[2]'>
                <Col xxl={11} xl={11} lg={11} md={11} sm={24} xs={24} className='left-col flex flex-col gap-[24px] 2xl:gap-[50px]'>
                    <div className='flex justify-start w-full'>
                        <img src={LOGO} alt="" className='h-[44px]' />
                    </div>
                    <div className='w-full flex items-center flex-auto'>
                        <div className='login-form'>
                            <div className='login-title'>Verify email</div>
                            {!loading && <div className='sub__login-title'>{isVerifiedSuccess ? "The email is verified successfully!" : "The account is active or token is expried!"}</div>}
                            <Spin spinning={loading} wrapperClassName="mt-[36px] rounded-[110px] overflow-hidden xl:mt-[48px] 2xl:mt-[100px]">
                                <button className='login-button' onClick={() => { onGoToLogin() }}>Go to login</button>
                            </Spin>
                        </div>
                    </div>
                </Col>
                <Col xxl={13} xl={13} lg={13} md={13} sm={0} xs={0} className='right-col p-[15px] md:p-[30px]'>
                    <CarouselLoginAndRegister onIndexChange={(value) => { setIndex(value) }} />
                </Col>
            </Row>
        </div> */}

      {/* <div className="sm:px-[32px] md:px-[73px] min-h-screen 2xl:min-h-screen flex justify-center items-center  w-full relative"> */}
      <div className="sm:px-[32px] md:px-[73px] min-h-screen lg:min-h-[1080px] 3xl:min-h-screen  flex justify-center items-center lg:items-baseline 3xl:items-center pt-16  w-full relative">
        <div
          style={{
            backgroundImage: `url(${AuthBackground})`,
            backgroundSize: "cover",
          }}
          className={`image-wrapper`}
        >
          <div className="mx-auto pt-[7%] pb-[15%] sm:pb-0 sm:pl-10 sm:pt-10 w-full ">
            <img
              src={LOGO}
              alt=""
              className="h-8 mx-auto sm:mx-0 sm:h-[33px]"
            />
          </div>
          <div className="footer-login">
            <RetailerAuthFooter />
          </div>
        </div>

        <div className="w-full log-box h-[90vh] sm:h-auto sm:w-2/3 lg:w-2/5 bg-[#060606] rounded-[9px] sm:rounded-[16px] overflow-hidden absolute bottom-0 sm:relative z-10">
          {/* <div className="flex min-h-full flex-col justify-center px-6 pt-4 sm:mb-4 lg:px-8 border">
                    <h1 className="text-xl font-bold text-white" >Congratulations</h1>
                    <p className="my-3 text-sm text-slate-300" >{email}</p>
                    <h1 className="text-white" >Email Verified</h1>
                    <div className="mt-10" >
                        <p className='mt text-xs opacity-80 text-[#D7D4D4] ' >Redirecting to login in ..... {count}</p>

                    </div>
                </div> */}
          <div className="flex min-h-full flex-col justify-center px-6 pt-4 sm:mb-4 lg:px-8">
            {email != null ? (
              <div className="mt-36 sm:mt-auto sm:mb-10">
                <div>
                  <video
                    autoPlay
                    loop
                    muted
                    playsInline
                    className=" z-40 mx-auto"
                  >
                    <source src="/verifiedEmail.webm" type="video/webm" />
                    Your browser does not support the video tag.
                  </video>
                </div>
                <div className="sm:mx-auto sm:w-full sm:max-w-sm ">
                  <h2 className=" text-center text-2xl font-bold leading-9 tracking-tight text-white mt-2">
                    Congratulations <span>{name}</span>
                  </h2>
                  <p className="mt-2 text-xs text-slate-500">{email}</p>
                  <p className="text-center font-bold text-sm text-gray-300 mt-1">
                    Email verified
                  </p>
                </div>
                <div className="mt-10">
                  <p className="mt text-xs opacity-80 text-[#D7D4D4] ">
                    Redirecting to login in ..... {count}
                  </p>
                </div>
              </div>
            ) : (
              <>
                {failedComponent ? (
                  <>
                    <div className="mt-36 sm:mt-auto sm:mb-10">
                      <div>
                        {/* <video autoPlay loop muted playsInline className=' z-40 mx-auto'>
                                    <source src="/email-error.webm" type="video/webm" />
                                    Your browser does not support the video tag.
                                </video> */}
                      </div>
                      <div className="sm:mx-auto sm:w-full sm:max-w-sm ">
                        <h2 className=" text-center text-2xl font-bold leading-9 tracking-tight text-white mt-2">
                          Verification failed!
                        </h2>
                        <p className="mt-2 text-xs text-slate-500">
                          We couldn't verify your email. Please ensure the link
                          is correct or try requesting a new verification email.
                          If the issue persists, contact support.
                        </p>
                      </div>
                      {/* <div className="mt-10" >
                            <p className='mt text-xs opacity-80 text-[#D7D4D4] ' >Redirecting to login in ..... {count}</p>

                        </div> */}
                    </div>
                  </>
                ):(
                    <>
                          <div className="mt-36 sm:mt-auto sm:mb-10">
                <div>
                  <video
                    autoPlay
                    loop
                    muted
                    playsInline
                    className=" z-40 mx-auto"
                  >
                    <source src="/verifiedEmail.webm" type="video/webm" />
                    Your browser does not support the video tag.
                  </video>
                </div>
                <div className="sm:mx-auto sm:w-full sm:max-w-sm ">
                  <h2 className=" text-center text-2xl font-bold leading-9 tracking-tight text-white mt-2">
                    Congratulations <span>{name}</span>
                  </h2>
                  <p className="mt-2 text-xs text-slate-500">{email}</p>
                  <p className="text-center font-bold text-sm text-gray-300 mt-1">
                    Email verified
                  </p>
                </div>
                <div className="mt-10">
                  <p className="mt text-xs opacity-80 text-[#D7D4D4] ">
                    Redirecting to login in ..... {count}
                  </p>
                </div>
              </div>
                    </>

                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default VerifyEmail;
