import React, { useEffect, useState } from "react";
import "./styles.scss";
import AudioIcon from "../../../../assets/images/project/audio.png";
import NoAudioIcon from "../../../../assets/images/project/no-audio.png";
import assetApi from "../../../../api/asset.api";

const SongsPlayer = () => {
    const [songs, setSongs] = useState([]); // Store the list of songs
    const [currentSongIndex, setCurrentSongIndex] = useState(0); // Track current song index
    const [audio, setAudio] = useState(null); // State to manage the Audio instance
    const [isPlaying, setIsPlaying] = useState(false); // Track if music is playing
    const [selectedSongId, setSelectedSongId] = useState(null);


    const togglePlay = () => {
        setIsPlaying((prev) => !prev);
    };


    useEffect(() => {
        console.log("retailkerrrrr coming this page also");
        
        const fetchSelectedSongId = async () => {
            // Replace with your API call or logic to get the ID
            const fetchedId = "672b2fbfdd2f449432899417"; // Example ID from API
            setSelectedSongId(fetchedId);
        };
console.log('first');

        fetchSelectedSongId();
    }, []);

    useEffect(() => {
        console.log('second');
        
        const fetchSongs = async () => {
            const data = {
                isOnlyNonDisable: true,
                limit: 1000,
                page: 1,
            };
            const response = await assetApi.getAllSongs(data);
    
            // If `selectedSongId` is present, prioritize that song
            let orderedSongs;
            if (selectedSongId) {
                console.log("Fetched songs:", response.results);
                console.log("Selected Song ID:", selectedSongId);
                
                const selectedSong = response.results.find(song => song.id === selectedSongId);
                console.log(selectedSong,'JJJJJJ');
                
                orderedSongs = selectedSong
                    ? [selectedSong, ...response.results.filter(song => song.id !== selectedSongId)]
                    : response.results;
            } else {
                console.log('else caseee');
                
                // If no `selectedSongId`, use the default order
                orderedSongs = response.results;
            }
    
            setSongs(orderedSongs);
        };
    
        fetchSongs();
    }, [selectedSongId]);

    useEffect(() => {
        if (songs.length > 0) {
            const audioInstance = new Audio(`${process.env.REACT_APP_UPLOAD_ENDPOINT}/${songs[currentSongIndex]?.filePath}`);
            setAudio(audioInstance);

            const playAudio = async () => {
                try {
                    await audioInstance.play(); // Attempt to play the audio
                } catch (error) {
                    console.error("Error playing audio:", error);
                }
            };

            // Play the audio and set up event listener for when the song ends
            audioInstance.addEventListener('ended', () => {
                setCurrentSongIndex((prevIndex) => (prevIndex + 1) % songs.length); // Loop to next song
            });

            // Call the play function
            playAudio();

            return () => {
                audioInstance.pause();
                audioInstance.removeEventListener('ended', () => {});
            };
        }
    }, [currentSongIndex, songs]);

    useEffect(() => {
        if (audio) {
            if (isPlaying) {
                audio.play().catch((error) => {
                    console.error("Error playing audio:", error);
                });
            } else {
                audio.pause();
            }
        }
    }, [isPlaying, audio]);

    return (
        <>
            <button className="btn-mute-background-music" onClick={togglePlay}>
                <img src={isPlaying ? AudioIcon : NoAudioIcon} alt="Toggle Play" />
            </button>
        </>
    );
};

export default SongsPlayer;
