import './style.css'
const RetailerOnboardingStartYourJourney=({ handleNext ,setIsTutorial,handleSkip})=>{

   const handleTutorial=()=>{
    setIsTutorial(true)
   }
 
    return (
        <>

        <div className="w-full h-full p-3  ">
<div className="flex flex-col justify-between   ">
<div className="min-h-20 w-full   flex flex-col  justify-center items-center">
    <h2 className="text-lg  inter-bold  text-white ">Start Your Journey</h2>
    <h2 className="text-lg inter-bold  text-white ">With <span className="bg-gradient-to-r from-[#24ddfe]  to-[#9b1afe] inline-block text-transparent inter-bold  bg-clip-text">Metadrob</span></h2>

</div>
<div className="min-h-20 w-full   flex justify-center items-start  text-center p-2 ">
    <p className="text-center flex items-center  text-white  text-md inter-light">Explore at your own pace with a guided tour, or watch our video tutorial for a quick overview of the product and how to navigate it like a pro. </p>
</div>

<div className="min-h-20 w-full flex flex-col ">
    {/* fist div for butten  */}
    <div className="w-full  flex  justify-center  gap-6 ">
        {/* fist butten  */}
        <button className=" min-w-10 w-28 rounded-md  font-bold bg-gradient-to-r from-[#24ddfe]  to-[#9b1afe] text-white "onClick={handleNext}>Guided Tour</button>
        <button className="border min-w-10 w-28 min-h-10 rounded-md border-white font-bold text-white" onClick={handleTutorial}>Watch Tutorial</button>

    </div>
    {/* second dev for skip button  */}
    <div className="w-full text-white font-medium min-h-5 flex justify-center items-center p-3 ">
    <p className='cursor-pointer' onClick={handleSkip}> Skip {'>>'}</p>

    </div>
</div>

</div>

        </div>
        </>
    )
}

export default RetailerOnboardingStartYourJourney