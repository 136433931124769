import { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import CameraHelper from "../../../../../components/canvasContainer/components/cameraControls/CameraHelper";

const CameraControlAdminDecorative = forwardRef(({}, ref) => {
  const cameraRef = useRef();
  const keyStates = useRef({});

  useImperativeHandle(ref, () => ({
    selectWall: (el) => {},
    cameraRef: () => {
      return cameraRef.current;
    },
    fitToBox: (box) => {
      cameraRef.current.fitToBox(box, true, {
        paddingLeft: 0.1,
        paddingRight: 0.1,
        paddingBottom: 0.2,
        paddingTop: 0.2,
      });
    },
  }));

  useEffect(() => {
    cameraRef.current.setLookAt(0, 1.5, 5, 0, 0, 0, false);
    // cameraRef.current.minZoom = 1;
    // cameraRef.current.maxZoom = 1;
    // cameraRef.current.truckSpeed = 5;
    // cameraRef.current.mouseButtons.wheel = CameraControlsDefault.ACTION.NONE
    if (cameraRef.current) {
      cameraRef.current.saveState();
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", activeKeyState);
    document.addEventListener("keyup", deActiveKeyState);
    document.addEventListener("visibilitychange", onTabActiveChange);
    // gl.domElement.addEventListener('wheel', onWheel)

    return () => {
      document.removeEventListener("keydown", activeKeyState);
      document.removeEventListener("keyup", deActiveKeyState);
      document.removeEventListener("visibilitychange", onTabActiveChange);
      // gl.domElement.removeEventListener('wheel', onWheel)
    };
  }, []);

  const onTabActiveChange = () => {
    if (document.hidden) {
      keyStates.current = {};
    }
  };

  // const onWheel = e => {
  //     if(e.deltaY < 0){
  //         cameraRef.current.forward( 0.4, true )
  //     } else {
  //         cameraRef.current.forward( -0.4, true )
  //     }
  // }

  // useFrame((state, delta) => {
  //     const deltaTime = Math.min(0.05, delta);
  //     controls(deltaTime);
  // });

  const checkKeydownInCanvas = (e) => {
    return e.target.tagName === "BODY";
  };

  const activeKeyState = (event) => {
    if (checkKeydownInCanvas(event)) {
      keyStates.current[event.code] = true;
    }
  };

  const deActiveKeyState = (event) => {
    keyStates.current[event.code] = false;
  };

  return (
    <>
      <CameraHelper ref={cameraRef} smoothTime={1} />
    </>
  );
});
export default CameraControlAdminDecorative;
