import React from 'react';
import AuthBackground from "../../../assets/images/AuthBackground.png";
import LOGO from "../../../assets/images/LOGO.svg";
import LoginFooterImage1 from "../../../assets/images/login/LoginFooterImage1.png";
import LoginFooterImage2 from "../../../assets/images/login/LoginFooterImage2.png";
import LoginFooterImage3 from "../../../assets/images/login/LoginFooterImage3.png";
import LoginFooterImage4 from "../../../assets/images/login/LoginFooterImage4.png";
import LoginFooterImage5 from "../../../assets/images/login/LoginFooterImage5.png";
import LoginFooterImage6 from "../../../assets/images/login/LoginFooterImage6.png";
import RetailerAuthFooter from '../../../components/retailerComponents/retailerAuthFooter/RetailerAuthFooter';

const SentEmail = () => {
    return (
        // <div>
        //   <video autoPlay loop muted playsInline>
        //     <source src="/path/to/your-animation.webm" type="video/webm" />
        //     Your browser does not support the video tag.
        //   </video>
        // </div>
        <>
            {/* <div className="sm:px-[32px] md:px-[73px] min-h-screen flex justify-center items-center w-full relative"> */}
            <div className="sm:px-[32px] md:px-[73px] min-h-screen lg:min-h-[1080px] 3xl:min-h-screen  flex justify-center items-center lg:items-baseline 3xl:items-center pt-16  w-full relative">
                <div
                    style={{
                        backgroundImage: `url(${AuthBackground})`,
                        backgroundSize: "cover",
                    }}
                    className={`login-image-wrapper`}
                >
                    <div className="mx-auto pt-[7%]  pb-[15%] sm:pb-0 sm:pl-10 sm:pt-10 w-full ">
                        <img
                            src={LOGO}
                            alt=""
                            className="h-8  mx-auto sm:mx-0 sm:h-[33px]"
                        />
                    </div>
                    <div className="footer-login" >
                        <RetailerAuthFooter />
                    </div>
                </div>

                <div className="w-full log-box h-[90vh] sm:h-auto sm:w-2/3 lg:w-2/5 bg-[#060606] rounded-[9px] sm:rounded-[16px] overflow-hidden absolute bottom-0 sm:relative z-10">
                    <div className="flex min-h-full flex-col justify-center px-6 pt-4 sm:mb-4 lg:px-8 ">


                        <div className="mt-36 sm:mt-auto sm:mb-10">
                            <div>
                                <video autoPlay loop muted playsInline className=' z-40 mx-auto'>
                                    <source src="/sent-email.webm" type="video/webm" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                            <div className="sm:mx-auto sm:w-full sm:max-w-sm ">
                                <h2 className=" text-center text-2xl font-medium leading-9 tracking-tight text-white mt-2">Verify Email</h2>
                                <p className="text-center font-normal text-sm text-gray-300 mt-2">
                                    We’ve sent a verification link to your email. Please check your inbox and click the link to verify your email address and continue setting up your account.
                                </p>
                            </div>

                        </div>
                        <div className="mt-auto" >
                            <hr className="w-full opacity-20 mt-3" />
                            <p className="text-xs text-center mt-2 lg:my-3 text-gray-600">
                                The most innovative companies supported metadrob
                            </p>

                            <footer
                                className="flex footer-com flex-col items-center text-centerx mt-auto sm:mt-0">
                                <div className="container">
                                    <div className="flex justify-evenly">
                                        <div className="mb-6 lg:mb-0 flex">
                                            <img
                                                src={LoginFooterImage1}
                                                className=" rounded-md w-10 md:w-16 object-contain" />
                                        </div>
                                        <div className="mb-6 lg:mb-0 flex">
                                            <img
                                                src={LoginFooterImage2}
                                                className=" rounded-md w-8 md:w-10 object-contain" />
                                        </div>
                                        <div className="mb-6 lg:mb-0 flex">
                                            <img
                                                src={LoginFooterImage3}
                                                className=" rounded-md w-9 md:w-12 object-contain" />
                                        </div>
                                        <div className="mb-6 lg:mb-0 flex">
                                            <img
                                                src={LoginFooterImage4}
                                                className=" rounded-md w-10 md:w-16 object-contain" />
                                        </div>
                                        <div className="mb-6 lg:mb-0 flex ">
                                            <img
                                                src={LoginFooterImage5}
                                                className=" rounded-md w-9 md:w-12  object-contain " />
                                        </div>
                                        <div className="mb-6 lg:mb-0 flex">
                                            <img
                                                src={LoginFooterImage6}
                                                className=" rounded-md w-9 md:w-12 object-contain " />
                                        </div>
                                    </div>
                                </div>
                            </footer>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SentEmail;
