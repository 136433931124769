import { Modal } from "antd";
import { useEffect, useRef, useState } from "react";
import "./styles.scss";
import closeIcon from "../../assets/images/project/close.svg";
import TextArea from "antd/es/input/TextArea";
import axios from "axios";
import { useParams } from "react-router-dom";

const ModalProjectName = (props) => {
  const {
    open,
    IsModalOpen,
    onOk = () => {},
    placeholder = "Enter Here...",
    title = "Project Name",
    checkComponent,
  } = props;

  const projectNameRef = useRef();
  const timeOut = useRef();
  const [projectName, setProjectName] = useState();
  const { id } = useParams();

  const handleSave = async () => {
    try {
      const BASE_URL = process.env.REACT_APP_ENDPOINT;
      const response = await axios.put(`${BASE_URL}/project/project/${id}`, {
        name: projectName,
      });
      if (response.status === 200) {
        onOk(projectName);
        IsModalOpen(false);
      }
    } catch (error) {
      console.error("Error updating project name:", error);
    }
  };

  useEffect(() => {
    if (open && checkComponent) {
      timeOut.current = setTimeout(() => {
        if (projectNameRef.current) {
          projectNameRef.current.focus();
        }
      }, 200);
    } else {
      timeOut.current = setTimeout(() => {
        const draftName = "Drafted"; // Get next draft name
        onOk(draftName);
      }, 200);
    }

    return () => {
      clearTimeout(timeOut.current);
    };
  }, [open]);

  const handleOk = () => {
    if (!projectName.trim()) {
      const draftName = "Drafted";
      onOk(draftName);
    } else {
      onOk(projectName);
    }
  };
  const handleModalClose = () => {
    console.log("coming secton close modaleeeeeeeee");
    IsModalOpen(false);
  };
  return (
    <>
      <Modal
        title={null}
        footer={null}
        open={open}
        closable={true}
        onCancel={() => {
          handleModalClose(false);
        }}
        className="retailer-modal-project-name"
        closeIcon={
          <img src={closeIcon} alt="" onClick={() => handleModalClose()} />
        }
        centered
      >
        <div className="text-project-name">{title}</div>
        <div className="text-description mt-[6px]">
          Enter your project name here !
        </div>
        <TextArea
          ref={projectNameRef}
          placeholder={placeholder}
          className="input-project-name mt-[22px]"
          autoSize={{
            minRows: 3,
            maxRows: 3,
          }}
          value={projectName}
          onChange={(e) => {
            setProjectName(e.target.value);
          }}
          onPressEnter={handleOk}
        />
        <div className="flex justify-center mt-[34px]">
          <button
            className="btn-save"
            onClick={() => {
              handleSave();
            }}
          >
            Save
          </button>
        </div>
      </Modal>
    </>
  );
};
export default ModalProjectName;