import { useEffect } from "react"
import { RiFacebookFill, RiInstagramLine, RiLinkedinFill } from "react-icons/ri";
import { GoArrowUpRight } from "react-icons/go";
import { IoLocationOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
const RetailerAuthFooter = () => {
    const navigate = useNavigate();
    useEffect(() => {
    }, [])

    return <>
        <div className=" bg-black w-full bg-opacity-45 mx-auto pt-6  absolute bottom-0" >
            <div className="w-11/12 flex  mx-auto" >
                <div className="w-1/2" >

                    <div >
                        <img
                            src="/meta-logo2.png"
                            alt=""
                            className="h-7"
                        />
                    </div>

                </div>
            </div>
            <div className="w-11/12 flex  mx-auto" >
                <div className="w-1/2 " >


                    <div className=" w-2/4 mt-2" >
                        <p className="text-[#FCFCFD] font-light text-xs text-left" >Metadrob is your ultimate Virtual Retail Partner, offering a comprehensive suite of virtual store tools to build, customize, and launch your virtual retail showroom with ease. Elevate your e-commerce game and turn your vision into a digital shopping reality.</p>

                    </div>

                </div>
                <div className=" w-1/2 " >
                    <div className="flex w-full justify-end text-xs " >
                        <div className=" w-1/3  flex justify-end  text-white text-right" >
                            <div className="mt-auto w-fit h-fit">
                                <div className="flex justify-end">
                                    <div className="w-fit flex" >
                                        <IoLocationOutline />
                                        <p>USA</p>
                                    </div>

                                </div>
                                <p>54, Montrose Ave</p>
                                <p>Summit NJ 07901, USA</p>
                            </div>


                        </div>


                    </div>
                    <div className=" flex justify-end gap-x-7" >
                        <div className=" w-fit flex justify-end  text-white text-right" >
                            <div className="mt-auto  w-fit h-fit">
                                <div className="flex justify-end" >
                                    <div className="w-fit flex" >
                                        <IoLocationOutline />
                                        <p>India</p>
                                    </div>

                                </div>
                                <p>3rd Floor, 4, </p>
                                <p>Lal Nivas, Heerabagh </p>
                                <p>Rambagh,Jaipur, India - 302001</p>
                            </div>


                        </div>
                        <div className=" w-fit  flex justify-end  text-white text-right" >
                            <div className="mt-auto w-fit h-fit">
                                <div className="flex justify-end" >
                                    <div className="w-fit flex" >
                                        <IoLocationOutline />
                                        <p>Bahrain</p>
                                    </div>

                                </div>
                                <p>P.O. Box 3294, Manama </p>
                                <p>Kingdom of Bahrain</p>
                            </div>


                        </div>

                    </div>

                </div>
            </div>
            <div className="w-11/12 flex  mx-auto my-3 text-[10px] xl:text-xs" >
                <div className=" w-2/4 flex justify-start gap-4" >
                    <a href="https://www.metadrob.com/terms-and-conditions/" className="text-[#929292] hover:text-white">Terms & Conditions</a>
                    <a href="https://www.metadrob.com/privacy-policy/" className="text-[#929292] hover:text-white" >Privacy Policy</a>
                    <a href="https://www.metadrob.com/shipping-and-delivery/" className="text-[#929292] hover:text-white" >Shipping and Delevery</a>
                    <a href="https://www.metadrob.com/cancellation-and-refund-policy/" className="text-[#929292] hover:text-white" >Cancellation and Refund</a>

                </div>
                <div className=" w-2/4 flex justify-end" >
                    <p className="text-[#929292] text-[13px] font-normal">COPYRIGHT © 2023 ALL RIGHTS RESERVED BY METADROB</p>

                </div>

            </div>

            {/* <div className="  mt-8 w-full flex " > */}
            {/* <div className="w-1/2" >

                    <div >
                        <img
                            src="/meta-logo2.png"
                            alt=""
                            className="h-7"
                        />
                    </div> */}
            {/* <div className=" w-2/4 mt-2" >
                        <p className="text-[#FCFCFD] font-light text-xs text-left" >Metadrob is your ultimate Virtual Retail Partner, offering a comprehensive suite of virtual store tools to build, customize, and launch your virtual retail showroom with ease. Elevate your e-commerce game and turn your vision into a digital shopping reality.</p>

                    </div> */}
            {/* </div> */}

            {/* <div className="w-1/2 border flex justify-end" >
                    <div className=" border text-right text-white" >
                    <p>i USA</p>
                    <p>54, Montrose Ave</p>
                    <p>Summit NJ 07901, USA</p>

                        

                    </div>

                </div> */}


            {/* </div> */}
            {/* <div className=" flex mx-8 mt-2 " >
                <div className=" w-1/3" >
                    <p className="text-gray-400 font-light text-sm text-left" >Metadrob is your ultimate Virtual Retail Partner, offering a comprehensive suite of virtual store tools to build, customize, and launch your virtual retail showroom with ease. Elevate your e-commerce game and turn your vision into a digital shopping reality.</p>

                </div>
                <div className=" w-1/3" >
                    <div className=" w-2/3 mx-auto" >
                        <h1 className="text-xl text-left text-white mb-2" >Join our VIP list</h1>
                        <div className="flex bg-white rounded-[3px] p-1" >
                            <input type="text" placeholder="Your email address"
                                className="block w-2/3 rounded-md py-2 text-sm bg-white text-slate-900   placeholder:font-normal placeholder:text-gray-800  outline-none pl-3"
                            />
                            <button type="submit" className="w-1/3 bg-gradient-to-r from-[#16F6FE] to-[#AB00FF] font-bold text-white rounded-[2px]" >Join</button>

                        </div>


                    </div>

                </div>
                <div className="w-1/3 flex sm:justify-end justify-between" >
                    <div className=" sm:w-1/3 w-full" >
                        <div className="flex justify-between" >
                            <p className="text-white text-xl font-light" >Lets Connect</p>
                            <GoArrowUpRight color="white" size={30} />
                        </div>
                        <div className="flex justify-between mt-2" >
                            <div className="border rounded-full p-2" >
                                <RiFacebookFill color="white" size={30} />
                            </div>
                            <div className="border rounded-full p-2" >
                                <RiInstagramLine color="white" size={30} />
                            </div>
                            <div className="border rounded-full p-2" >
                                <RiLinkedinFill color="white" size={30} />
                            </div>

                        </div>

                    </div>

                </div>

            </div> */}

        </div>
    </>
}
export default RetailerAuthFooter