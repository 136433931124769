import { Modal } from "antd"
import "./style.css"
import { IoClose } from "react-icons/io5";
const ModalTermsAndConditions = ({
    open,
    onClose = () => { },
    onAgree = () => { },
}) => {

    return <>

        <Modal
            open={open}
            width={"50%"}
            footer={null}
            closeIcon={<IoClose color="white" />}
            destroyOnClose={true}
            closable={true}

            centered
            className="custom-modal"
            // style={{ backgroundColor: '#1a1a1a', color: '#fff',padding:0,margin:0 }}
            onCancel={() => { onClose() }}
        >
            <div className="text-xs w-full mod-box" style={{ fontFamily: "Inter" }}>
                <div className="font-bold text-2xl my-3">
                    Terms and Conditions
                </div>
                {/* <h1 className="mt-2 text-xl font-bold">Your agreement</h1> */}
                <div className="mt-2" >
                    <p className="text-slate-400" ><span className="font-bold text-slate-300">Introduction</span> : These Terms and Conditions (the "Terms") govern your use of the Metadrob website (the "Site") and any services offered by Metadrob (the "Services"). By using the Site or Services, you agree to be bound by these Terms </p>
                </div>
                <div className="mt-2" >
                    <p className="text-slate-400" ><span className="font-bold text-slate-300">Eligibility</span> : The Site and Services are only available to individuals who are 18 years of age or older. By using the Site or Services, you represent and warrant that you are 18 years of age or older
                    </p>
                </div>
                <div className="mt-2" >
                    <p className="text-slate-400" ><span className="font-bold text-slate-300">Account Creation</span> : In order to use certain features of the Site or Services, you may need to create an account. When creating an account, you must provide accurate and complete information. You are responsible for maintaining the security of your account and password. You are also responsible for all activities that occur under your account.
                    </p>
                </div>
                <div className="mt-2" >
                    <p className="text-slate-400" ><span className="font-bold text-slate-300">Intellectual Property</span> : All content on the Site, including text, images, videos, and software, is the property of Metadrob or its licensors and is protected by copyright law. You may not copy, modify, distribute, or create derivative works from any content on the Site without the express written permission of Metadrob.
                    </p>
                </div>
                <div className="mt-2" >
                    <p className="text-slate-400" ><span className="font-bold text-slate-300">Links to Other Sites</span> : The Site may contain links to other websites. These links are provided for convenience only and do not imply any endorsement of the content on those websites. Metadrob is not responsible for the content of any linked websites
                    </p>
                </div>
                <div className="mt-2" >
                    <p className="text-slate-400" ><span className="font-bold text-slate-300">Disclaimer of Warranties</span> : The Site and Services are provided on an "as is" basis. Metadrob makes no representations or warranties of any kind, express or implied, regarding the Site or Services, including but not limited to the accuracy, completeness, or reliability of any content on the Site.
                    </p>
                </div>
                <div className="mt-2" >
                    <p className="text-slate-400" ><span className="font-bold text-slate-300">Limitation of Liability</span> : In no event shall Metadrob be liable to you for any damages, whether direct, indirect, special, incidental, or consequential, arising out of or in connection with your use of the Site or Services, including but not limited to lost profits, lost data, or damage to your computer system.
                    </p>
                </div>
                <div className="mt-2" >
                    <p className="text-slate-400" ><span className="font-bold text-slate-300">Governing Law</span> : These Terms shall be governed by and construed in accordance with the laws of the State of California, without regard to its conflict of laws provisions. Any dispute arising out of or relating to these Terms shall be subject to the exclusive jurisdiction of the courts located in the State of California.
                    </p>
                </div>
                <div className="mt-2" >
                    <p className="text-slate-400" ><span className="font-bold text-slate-300">Entire Agreement</span> : These Terms constitute the entire agreement between you and Metadrob regarding the use of the Site and Services. These Terms supersede all prior or contemporaneous communications, representations, or agreements, whether oral or written.
                    </p>
                </div>
                <div className="mt-2" >
                    <p className="text-slate-400" ><span className="font-bold text-slate-300">Changes to Terms</span> : Metadrob may modify these Terms at any time. The most current version of the Terms will be posted on the Site. By continuing to use the Site or Services after any modification to the Terms, you agree to be bound by the modified Terms.
                    </p>
                </div>
                <div className="mt-2" >
                    <p className="text-slate-400" ><span className="font-bold text-slate-300">Contact Us</span> : If you have any questions about these Terms, please contact us at <a href="mailto:info@metadrob.com" className="text-[#16F6FE]">info@metadrob.com</a>.
                    </p>
                </div>

                {/* <div className="mt-[32px] flex justify-center">
                    <button className="btn-agree" onClick={() => {onAgree()}}>
                        Agree
                    </button>
                </div>  */}
            </div>
        </Modal>
    </>
}
export default ModalTermsAndConditions