import { useSelector } from "react-redux"
import { getIsMusicPaused, setMusicIsPaused } from "../../../../redux/spotifySlice"
import { useEffect, useMemo, useRef } from "react"
import { useAppDispatch } from "../../../../redux"
import { getListAudios, setListAudios } from "../../../../redux/audioSlice"
import _ from "lodash"
import { getAssetsUrl } from "../../../../utils/util"

const YourAudioContainer = () => {
    const dispatch = useAppDispatch()
    const audioRef = useRef()
    const intervalRef = useRef()
    const musicIsPaused = useSelector(getIsMusicPaused)
    const listAudios = useSelector(getListAudios)
    const playingAudioSrc = useMemo(() => {
        return _.get(_.find(listAudios, { isPlaying: true }), ['filePath'], '')
    }, [listAudios])

    useEffect(() => {
        return () => {
            dispatch(setMusicIsPaused(true))
        }
    }, [])

    useEffect(() => {
        onPlayAudio()
    }, [musicIsPaused, playingAudioSrc]) // Re-run on src change

    const onNextAudio = () => {
        if (listAudios.length === 1) {
            onPlayAudio()
            return
        }
        const currentIndex = _.findIndex(listAudios, { isPlaying: true })
        const nextIndex = currentIndex + 1

        const cloneAudios = listAudios.map((el, index) => ({
            ...el,
            isPlaying: index === nextIndex % listAudios.length,
        }))

        dispatch(setListAudios(cloneAudios))
    }

    const clearIntervalPlayMusic = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current)
        }
    }

    const onPlayAudio = () => {
        clearIntervalPlayMusic()
        if (!audioRef.current) return
        if (musicIsPaused) {
            audioRef.current.pause()
            return
        }
        audioRef.current.play().catch(err => {
            console.error("Error playing audio:", err)
        })
    }

    return <>
        {playingAudioSrc && <audio 
            ref={audioRef}
            src={getAssetsUrl(playingAudioSrc)}
            hidden
            onLoadedData={onPlayAudio}
            onEnded={onNextAudio}
        />}
    </>
}

export default YourAudioContainer