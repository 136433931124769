// import { Modal } from "antd"
import "./test.css";
import { IoClose } from "react-icons/io5";
import { Modal, notification } from "antd";
import { useEffect, useState } from "react";
import {
  CONFIG_TEXT,
  PAYMENT_GATE,
  PAYMENT_STATUS,
  PLANS_TYPES,
  PRICING_PLAN_FEATURES_KEY,
  TRIAL_EXPIRED_DAYS,
  USER_SUBCRIPTION_KEY,
} from "../../utils/constants";
import { useSelector } from "react-redux";
import { getIsViewerMode } from "../../redux/modelSlice";
import { getUser, setUser } from "../../redux/appSlice";
import { userApi } from "../../api/user.api";
import { useNavigate } from "react-router-dom";

import moment from "moment";
import userSubcriptionApi from "../../api/userSubcription.api";
import { useAppDispatch } from "../../redux";
import _ from "lodash";
import MetadrobPricingPlans from "./components/metadrobPricingPlans/MetadrobPricingPlans";
import DrobAPricingPlans from "./components/drobAPricingPlans/DrobAPricingPlans";
import Lottie from "lottie-react";
import loadingAnimation from "../../assets/json/metadrob_loader.gif"
import ModalPaypalForPricingPlan from "./components/modalPaypalForPricingPlan/ModalPaypalForPricingPlan";
import global from "../../redux/global";
import { useAuthenticatedFetch } from "../../modules/shopify/hooks";
import { useParams } from "react-router-dom";
import { useAppBridgeRedirect } from "../../modules/shopify/hooks/useAuthenticatedFetch";

import { handleEventOccurance } from "../../pages/retailer/retailerHelper/analyticsEventHandler";
import "./modelPrice.css";
import { FaCheck } from "react-icons/fa6";
import { HiX } from "react-icons/hi";
import pricingPlanApi from "../../api/pricingPlan.api";
const TestModal = ({
  open,
  onClose = () => {},
  isPublishProject = false,
  onAgree = () => {},
}) => {
  const navigate = useNavigate();
  const { id: projectId } = useParams();
  const dispatch = useAppDispatch();
  const [planType, setPlanType] = useState(
    global.IS_DROB_A ? PLANS_TYPES.DIGITAL_SHOWCASE : PLANS_TYPES.E_COMMERCE
  );
  const isViewerMode = useSelector(getIsViewerMode);
  const user = useSelector(getUser);

  const [triedPlanIds, setTriedPlanIds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedPayPer, setSelectedPayPer] = useState("Monthly");

  const [isShowModalPaypal, setIsShowModalPaypal] = useState(false);
  const [purchasePlanInfo, setPurchasePlanInfo] = useState({
    plan: {},
    total: 0,
    payPer: "Yearly",
  });

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetch = global.IS_SHOPIFY ? useAuthenticatedFetch() : null;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const appBrigdeRedirect = global.IS_SHOPIFY ? useAppBridgeRedirect() : null;

  useEffect(() => {
    if (user?.id) {
      userApi.getListTriedPlanIds(user.id).then((rs) => {
        setTriedPlanIds(rs);
      });
    }
  }, [user?.id]);

  const onTrialPlan = (plan) => {
    if (!plan) {
      notification.warning({
        message: CONFIG_TEXT.PLEASE_CHOOSE_PLAN,
      });
      return;
    }
    if (!user?.id) {
      return;
    }
    console.log(plan.id, "Plan id");
    if (!triedPlanIds.includes(plan?.id)) {
      setIsLoading(true);
      const trialDays = _.get(
        _.find(_.get(plan, ["features"], []), {
          key: PRICING_PLAN_FEATURES_KEY.TRIAL_PERIOD,
        }),
        ["value"],
        TRIAL_EXPIRED_DAYS
      );

      const body = {
        userId: user.id,
        key: USER_SUBCRIPTION_KEY.PRICING_PLAN,
        value: {
          pricingId: plan?.id,
          stripeIntentSecret: null,
          numOfDate: trialDays,
          createdDate: moment(new Date()),
          expiredDate: moment(new Date()).add(trialDays, "day"),
          isTrial: true,
          paymentGate: "NONE",
        },
        paymentStatus: PAYMENT_STATUS.SUCCEEDED,
        active: true,
      };
      userSubcriptionApi
        .createSubcription(body, plan.id)
        .then((rs) => {
          userApi
            .updateLoggedInUser({
              triedPlanIds: [...triedPlanIds, plan?.id],
            })
            .then((userData) => {
              notification.success({
                message: "Subcribe Trial version!",
              });
              setIsLoading(false);
              dispatch(setUser(userData.user));
              setTriedPlanIds(_.get(userData, ["user", "triedPlanIds"], []));
              onClose();
            })
            .catch((err) => {
              setIsLoading(false);
              notification.error({
                message: _.get(
                  err,
                  ["response", "data", "message"],
                  `Can't update user!`
                ),
              });
            });
        })
        .catch((err) => {
          setIsLoading(false);
          notification.error({
            message: _.get(
              err,
              ["response", "data", "message"],
              `Can't subcribe Trial version!`
            ),
          });
        });
    }
  };

  const onPurchasePlan = async (plan, total, payPer) => {
    console.log("Call caoming");
    // Removed this logic for shopify
    if (global.IS_SHOPIFY) {
      console.log("shopifyh");

      setIsLoading(true);
      try {
        const data = await getPurchaseOneTimeUrl(total, plan);
        console.log("data", data);

        if (
          _.get(
            data,
            [
              "body",
              "data",
              "appPurchaseOneTimeCreate",
              "userErrors",
              "length",
            ],
            0
          )
        ) {
          setIsLoading(false);
          notification.error({
            message: _.get(
              data,
              [
                "body",
                "data",
                "appPurchaseOneTimeCreate",
                "userErrors",
                "0",
                "message",
              ],
              `Can't create app purchase one time!`
            ),
          });
        } else {
          const confirmationUrl = _.get(data, [
            "body",
            "data",
            "appPurchaseOneTimeCreate",
            "confirmationUrl",
          ]);
          console.log("confirmationUrl", confirmationUrl);
          if (confirmationUrl) {
            const appPurchaseOneTime = _.get(data, [
              "body",
              "data",
              "appPurchaseOneTimeCreate",
              "appPurchaseOneTime",
            ]);

            const body = {
              userId: user.id,
              key: USER_SUBCRIPTION_KEY.PRICING_PLAN,
              value: {
                amount: total,
                pricingId: plan.id,
                numOfDate: payPer === "Monthly" ? 30 : 365,
                createdDate: moment(new Date()),
                expiredDate: moment(new Date()).add(
                  payPer === "Monthly" ? 30 : 365,
                  "day"
                ),
                paymentGate: PAYMENT_GATE.SHOPIFY_BILLING,
                appPurchaseOneTime,
                userData: {
                  isPublishProject,
                  projectId,
                },
              },
            };
            const newSubcription = await userSubcriptionApi.createSubcription(
              body
            );
            setIsLoading(false);
            if (newSubcription.id) {
              appBrigdeRedirect(confirmationUrl);
            } else {
              notification.error({
                message: `Can't create subcription for ${plan.name}`,
              });
            }
          } else {
            setIsLoading(false);
            notification.error({
              message: "Can't retrive confirm url!",
            });
          }
        }
      } catch (err) {
        setIsLoading(false);
      }
    } else {
      setPurchasePlanInfo({
        plan,
        total,
        payPer,
      });
      setIsShowModalPaypal(true);
    }
  };

  const getPurchaseOneTimeUrl = async (total, plan) => {
    const body = {
      amount: total,
      currencyCode: "USD",
      name: `Payment for ${_.get(plan, ["name"])}`,
    };

    const response = await fetch("/shopify/get-purchase-one-time-url", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    const data = await response.json();

    return data;
  };

  const [checkComponentClick, setCheckComponentClick] = useState(false);
  const [updateUserEvents, setUpdateUserEvents] = useState(null);

  const handleEvent = (type) => {
    const id = type;
    const component_name = `${type} Billing`;
    const event_type = "pricingPlan_event";
    const data = handleEventOccurance(id, component_name, event_type, user);
    if (data) {
      setUpdateUserEvents(data);
      setCheckComponentClick(true);
    }
  };

  // const isViewerMode = useSelector(getIsViewerMode)
  const [plans, setPlans] = useState([]);
  const [firstPlan, setFirstPlan] = useState([]);
  const [secondPlan, setSecondPlan] = useState([]);
  const [thirdPlan, setThirdPlan] = useState([]);

  useEffect(() => {
    pricingPlanApi.getAvailablePricingPlans({ isDrobA: 0 }).then((rs) => {
      setPlans(rs);
      const first = rs?.find((item) => item.display === "first");
      setFirstPlan(first);

      const second = rs?.find((item) => item.display === "second");
      setSecondPlan(second);

      const third = rs?.find((item) => item.display === "third");
      setThirdPlan(third);

      // console.log(firstPlan,secondPlan,thirdPlan)
    });
  }, []);

  function getHiddenClass(item) {
    return item ? "" : " hidden-cls";
  }

  return (
    <>
      <Modal
        open={open}
        width={"50%"}
        footer={null}
        closeIcon={<IoClose color="white" />}
        destroyOnClose={true}
        closable={true}
        centered
        className="custom-modal3"
        // style={{ backgroundColor: '#1a1a1a', color: '#fff',padding:0,margin:0 }}
        onCancel={() => {
          onClose();
        }}
      >
        <div
          className="text-xs w-full mod-box "
          style={{ fontFamily: "Inter" }}
        >
          <div className="w-full px-3">
            <div className="flex w-full gap-1 mt-10">
              <div className="w-1/2 flex items-center">
                <h1 className="text-white text-lg md:text-2xl font-semibold">
                  The perfect plan awaits
                </h1>
              </div>
              <div className="w-1/2 flex justify-end text-white">
                <div className=" border border-[#918585] md:flex p-1 md:p-1.5 rounded-[8px]">
                  <button
                    id="Monthly"
                    className={`btn-select-pay-per text-[8px] md:text-[10px] p-1 md:p-1.5 px-2 rounded-[4px] w-full md:w-28 ${
                      selectedPayPer === "Monthly"
                        ? "bg-[#16F6FE] text-black"
                        : ""
                    } `}
                    onClick={() => {
                      handleEvent("Monthly");
                      setSelectedPayPer("Monthly");
                    }}
                  >
                    Pay Monthly
                  </button>
                  <button
                    id="Yearly"
                    className={`btn-select-pay-per text-[8px] md:text-[10px] p-1 md:p-1.5 px-2 rounded-[4px] w-full ${
                      selectedPayPer === "Yearly"
                        ? "bg-[#16F6FE] text-black"
                        : ""
                    } `}
                    onClick={() => {
                      handleEvent("Yearly");
                      setSelectedPayPer("Yearly");
                    }}
                  >
                    Pay Yearly (Save 20%)
                  </button>
                </div>
              </div>
            </div>
            <div className=" mt-8">
              <div className="md:flex lg:h-[470px] w-full gap-8 md:gap-5">
                <div className="md:w-1/3 border-[2.5px] border-[#16F6FE] rounded-[12px] mt-4">
                  <div class="  px-4 h-[93%] pb-5 pt-3 ">
                    <div className="flex justify-between">
                      <h1 className="head-plan text-[14px] mt-1 ">
                        {firstPlan?.name}
                      </h1>
                      <h1 className="text-white text-[20px] font-semibold mt-1 mr-2">
                        $
                        {selectedPayPer === "Monthly"
                          ? _.get(firstPlan, ["pricing", "monthly"])
                          : _.get(firstPlan, ["pricing", "yearly"])}
                      </h1>
                    </div>
                    <div className="my-4">
                      {/* <p style={{ lineHeight: "14px" }} className="text-[10px] font-normal text-white" >Easy to set up and use, Metadrob 3D storefront is perfect for starting your virtual store adventure.</p> */}
                      <p
                        style={{ lineHeight: "14px" }}
                        className="text-[10.3px] font-normal text-white"
                      >
                        {firstPlan?.description}
                      </p>
                    </div>
                    <div className="w-full">
                      <button
                        onClick={() => {
                          onPurchasePlan(
                            firstPlan,
                            (selectedPayPer === "Monthly"
                              ? _.get(firstPlan, ["pricing", "monthly"])
                              : _.get(firstPlan, ["pricing", "yearly"])) *
                              (selectedPayPer === "Monthly" ? 1 : 12),
                            "Yearly"
                          );
                        }}
                        className=" w-full text-xs text-black bg-white py-2.5 rounded-md"
                      >
                        Get Started
                      </button>
                      {/* <p className="text-[9px] text-center mt-2 text-[#16F6FE] underline" >Start your 14-Days Free trial</p> */}
                      {!isViewerMode &&
                        !(triedPlanIds || []).includes(firstPlan?.id) && (
                          <>
                            <div
                              className="text-[9px] text-center mt-2 underline cursor-pointer"
                              onClick={() => {
                                onTrialPlan(firstPlan);
                              }}
                            >
                              Start your{" "}
                              {_.get(
                                _.find(_.get(firstPlan, ["features"], []), {
                                  key: PRICING_PLAN_FEATURES_KEY.TRIAL_PERIOD,
                                }),
                                ["value"],
                                TRIAL_EXPIRED_DAYS
                              )}
                              -Days Free trial
                            </div>
                          </>
                        )}
                      {!isViewerMode &&
                        (triedPlanIds || []).includes(firstPlan?.id) && (
                          <>
                            <div className="text-[9px] text-center mt-2">
                              Trial expired
                            </div>
                          </>
                        )}
                    </div>

                    <div className=" mt-3">
                      <p className="font-semibold text-[13px]">Features</p>
                      <p className="text-[10px] font-thin">
                        everything in the plan...
                      </p>
                    </div>
                    <div className=" mt-2 max-h-40 overflow-y-auto">
                      {firstPlan?.includedInfomation?.map((item, index) => {
                        // console.log(firstPlan,"first plan")
                        const status = item.split(" - ")[1];
                        // { console.log(secondPlan, "second plan") }
                        return (
                          <div className="flex text-white mt-3" key={index}>
                            <div
                              className={`rounded-full w-4 h-4 flex items-center justify-center mr-2 ${
                                status === "No"
                                  ? "bg-[#3D3D3D]"
                                  : "bg-[#16F6FE]"
                              } `}
                            >
                              {status === "No" ? (
                                <HiX color="black" size={10} />
                              ) : (
                                <FaCheck color="black" size={10} />
                              )}
                            </div>
                            <p className="text-xs">{item}</p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  {/* <div className=" w-full pt-auto  " >

                  <p className="text-center underline text-xs cursor-pointer" onClick={() => { onClose(); navigate('/dashboard/home/pricing') }}>View Plan</p>
                </div> */}
                  <p
                    className="text-center underline text-xs cursor-pointer"
                    onClick={() => {
                      onClose();
                      navigate("/dashboard/home/pricing");
                    }}
                  >
                    View Plan
                  </p>
                </div>

                <div className="md:w-1/3 mt-6 md:mt-0 border-[2.5px] border-[#16F6FE] rounded-[12px] h-full relative inline-block  bg-gradient-to-b from-[#AB00FF] to-[#16F6FE]">
                  <span
                    style={{
                      boxShadow:
                        "0 -10px 20px -5px white, -10px 0 20px -5px white, 10px 0 20px -5px white",
                    }}
                    class="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#1C1C1C] shadow-lg shadow-cyan-500/50 text-white rounded-full text-[8px] md:text-[10px] flex items-center p-1 px-2 sm:px-3 justify-center whitespace-nowrap"
                  >
                    Most Popular
                  </span>
                  <div class=" px-4 h-[93%] pb-5 ">
                    <div className="flex justify-between pt-3 ">
                      <h1 className="head-plan text-sm mt-1">
                        {secondPlan?.name}
                      </h1>
                      <h1 className="text-white text-[20px] font-semibold mt-1 mr-2">
                        $
                        {selectedPayPer === "Monthly"
                          ? _.get(secondPlan, ["pricing", "monthly"])
                          : _.get(secondPlan, ["pricing", "yearly"])}
                      </h1>
                    </div>
                    <div className="my-4">
                      <p
                        style={{ lineHeight: "14px" }}
                        className="text-[10.3px] font-normal text-white"
                      >
                        {secondPlan?.description}
                      </p>
                    </div>
                    <div className="w-full">
                      <button
                        onClick={() => {
                          onPurchasePlan(
                            secondPlan,
                            (selectedPayPer === "Monthly"
                              ? _.get(secondPlan, ["pricing", "monthly"])
                              : _.get(secondPlan, ["pricing", "yearly"])) *
                              (selectedPayPer === "Monthly" ? 1 : 12),
                            "Yearly"
                          );
                        }}
                        className="bg-[#1C1C1C] w-full text-xs text-white py-2.5 rounded-md"
                      >
                        Get Started
                      </button>
                      {/* <p className="text-[9px] text-center mt-2 underline" >Start your 14-Days Free trial</p> */}
                      {!isViewerMode &&
                        !(triedPlanIds || []).includes(secondPlan?.id) && (
                          <>
                            <div
                              className="text-[9px] text-center mt-2 underline cursor-pointer"
                              onClick={() => {
                                onTrialPlan(secondPlan);
                              }}
                            >
                              Start your{" "}
                              {_.get(
                                _.find(_.get(secondPlan, ["features"], []), {
                                  key: PRICING_PLAN_FEATURES_KEY.TRIAL_PERIOD,
                                }),
                                ["value"],
                                TRIAL_EXPIRED_DAYS
                              )}
                              -Days Free trial
                            </div>
                          </>
                        )}
                      {!isViewerMode &&
                        (triedPlanIds || []).includes(secondPlan?.id) && (
                          <>
                            <div className="text-[9px] text-center mt-2">
                              Trial expired
                            </div>
                          </>
                        )}
                    </div>

                    <div className=" mt-3">
                      <p className="font-semibold text-[13px]">Features</p>
                      <p className="text-[10px] font-thin">
                        everything in the plan...
                      </p>
                    </div>
                    <div className=" mt-2 max-h-36 overflow-y-auto">
                      {secondPlan?.includedInfomation?.map((item, index) => {
                        const status = item.split(" - ")[1];
                        // { console.log(secondPlan, "second plan") }
                        return (
                          <div className="flex text-white mt-3">
                            <div className="rounded-full w-4 h-4 flex items-center justify-center mr-2 bg-[#16F6FE]">
                              <FaCheck color="black" size={10} />
                            </div>
                            <p className="text-xs">{item}</p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <p
                    className="text-center underline text-xs cursor-pointer"
                    onClick={() => {
                      onClose();
                      navigate("/dashboard/home/pricing");
                    }}
                  >
                    View Plan
                  </p>
                </div>

                <div className="md:w-1/3 border-[2.5px] border-[#16F6FE] rounded-[12px] mt-6 md:mt-4 flex flex-col">
                  <div class=" px-4 pb-5 h-[93%]">
                    <div className="flex justify-between pt-3 ">
                      <h1 className="head-plan text-sm mt-1">
                        {thirdPlan.name}
                      </h1>
                      <h1 className="text-white text-[20px] font-semibold mt-1 mr-2">
                        {" "}
                        $
                        {selectedPayPer === "Monthly"
                          ? _.get(thirdPlan, ["pricing", "monthly"])
                          : _.get(thirdPlan, ["pricing", "yearly"])}
                      </h1>
                    </div>
                    <div className="my-4">
                      <p
                        style={{ lineHeight: "14px" }}
                        className="text-[10.3px] font-normal text-white"
                      >
                        {thirdPlan.description}
                      </p>
                    </div>
                    <div className="w-full">
                      <button
                        className=" w-full text-xs text-black bg-white hite py-2.5 rounded-md"
                        onClick={() => {
                          onPurchasePlan(
                            thirdPlan,
                            (selectedPayPer === "Monthly"
                              ? _.get(thirdPlan, ["pricing", "monthly"])
                              : _.get(thirdPlan, ["pricing", "yearly"])) *
                              (selectedPayPer === "Monthly" ? 1 : 12),
                            "Yearly"
                          );
                        }}
                      >
                        Get Started
                      </button>
                      {!isViewerMode &&
                        !(triedPlanIds || []).includes(thirdPlan?.id) && (
                          <>
                            <div
                              className="text-[9px] text-center mt-2 underline cursor-pointer"
                              onClick={() => {
                                onTrialPlan(thirdPlan);
                              }}
                            >
                              Start your{" "}
                              {_.get(
                                _.find(_.get(thirdPlan, ["features"], []), {
                                  key: PRICING_PLAN_FEATURES_KEY.TRIAL_PERIOD,
                                }),
                                ["value"],
                                TRIAL_EXPIRED_DAYS
                              )}
                              -Days Free trial
                            </div>
                          </>
                        )}
                      {!isViewerMode &&
                        (triedPlanIds || []).includes(thirdPlan?.id) && (
                          <>
                            <div className="text-[9px] text-center mt-2">
                              Trial expired
                            </div>
                          </>
                        )}
                    </div>

                    <div className=" mt-3">
                      <p className="font-semibold text-[13px]">Features</p>
                      <p className="text-[10px] font-thin">
                        everything in the plan...
                      </p>
                    </div>
                    <div className=" mt-2 max-h-36 lg:max-h-32 xl:max-h-36 overflow-y-auto mb-auto">
                      {thirdPlan?.includedInfomation?.map((item, index) => {
                        // { console.log(secondPlan, "second plan") }
                        return (
                          <div className="flex text-white mt-3" key={index}>
                            <div className="rounded-full w-4 h-4 flex items-center justify-center mr-2 bg-[#16F6FE]">
                              <FaCheck color="black" size={10} />
                            </div>
                            <p className="text-xs">{item}</p>
                          </div>
                        );
                      })}
                    </div>
                    
                  </div>
                  <p
                    className="text-center underline text-xs cursor-pointer"
                    onClick={() => {
                      onClose();
                      navigate("/dashboard/home/pricing");
                    }}
                  >
                    View Plan
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <ModalPaypalForPricingPlan
        open={isShowModalPaypal}
        purchasePlanInfo={purchasePlanInfo}
        isPublishProject={isPublishProject}
        onClose={() => {
          setIsShowModalPaypal(false);
        }}
      />
    </>
  );
};
export default TestModal;
