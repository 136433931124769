import React, { useState } from 'react';
import RetailerOnboardingStartYourJourney from './RetailerOnbordingComponents/RetailerOnboardingStartYourJourney';
import RetailerOboardingProfilePicture from './RetailerOnbordingComponents/RetailerOboardingProfilePicture';
import RetailerOnBoardingSocialConnection from './RetailerOnbordingComponents/RetailerOnBoardingSocialConnection';
import RetailerOnBoardingGreateJob from './RetailerOnbordingComponents/RetailerOnBoardingGreateJob';
import RetailerOnBoardingReceived from './RetailerOnbordingComponents/RetailerOnBoardingReceived';
import RetailerOnBoardingKeepUp from './RetailerOnbordingComponents/RetailerOnBoardingKeepUp';




const RetailerOnboardingAfterLogin = ({setIsTutorial,handleSkip}) => {
    const [step,setSteps]=useState(0)

    const handleNext=()=>{
      setSteps(prevStep => prevStep + 1);
    }

    const renderStepContent = (step, handleNext) => {
      switch (step) {
        case 0:
          return <RetailerOnboardingStartYourJourney handleNext={handleNext} setIsTutorial={setIsTutorial} handleSkip={handleSkip}/>;
        case 1:
          return <RetailerOboardingProfilePicture handleNext={handleNext} />;
        case 2:
          return <RetailerOnBoardingSocialConnection handleNext={handleNext} />;
        case 3:
          return <RetailerOnBoardingGreateJob handleNext={handleNext} />;
        case 4:
          return <RetailerOnBoardingReceived handleNext={handleNext} />;
        case 5:
          return <RetailerOnBoardingKeepUp handleNext={handleNext} handleSkip={handleSkip} />;
        default:
          return <RetailerOnboardingStartYourJourney handleNext={handleNext} />;
      }
    };

   return (
    <>
    <div className="w-full h-full fixed flex justify-center items-center top-0 left-0 bg-black bg-opacity-50" style={{ zIndex: 999 }}>
    <div
  className={`rounded-lg bg-black flex justify-center items-center ${
    step === 3
      ? 'w-72 sm:w-72 md:w-72 lg:w-72 xl:w-72'
      : 'w-11/12 sm:w-7/12 md:w-5/12 lg:w-4/12 xl:w-3/12'
  }`}
>
 
{renderStepContent(step, handleNext)}
    


</div>
</div>

    </>
   )
};

export default RetailerOnboardingAfterLogin;