import { useEffect, useRef, useState } from 'react';
import './style.css'

const RetailerOnBoardingTutorial=({ handleSkip })=>{



  const videos = [
    // "/videos/2110972-uhd_3840_2160_30fps.mp4", 
    // "/videos/3209763-uhd_3840_2160_25fps.mp4",
    // "/videos/4115236-uhd_4096_2160_25fps.mp4"
   `${ process.env.REACT_APP_S3_BASE_URL}/fe_video_files/2110972-uhd_3840_2160_30fps.mp4`,
   `${ process.env.REACT_APP_S3_BASE_URL}/fe_video_files/3209763-uhd_3840_2160_25fps.mp4`,
   `${ process.env.REACT_APP_S3_BASE_URL}/fe_video_files/4115236-uhd_4096_2160_25fps.mp4`,
  ];

      const [currentVideo, setCurrentVideo] = useState(0);
      const videoRef = useRef(null); // Create a ref for the video element
    
      // Handle next video
      const nextVideo = () => {
        setCurrentVideo((prev) => (prev === videos.length - 1 ? 0 : prev + 1));
      };
    
      // Handle previous video
      const prevVideo = () => {
        setCurrentVideo((prev) => (prev === 0 ? videos.length - 1 : prev - 1));
      };
    
      // Reload the video source whenever currentVideo changes
      useEffect(() => {
        if (videoRef.current) {
          videoRef.current.load(); // Trigger reload of the video element
        }
      }, [currentVideo]);
    
    
    return (
        <>

<div className="w-screen h-screen fixed flex justify-center items-center  bg-black bg-opacity-50" style={{ zIndex: 999 }}>
 <div className="w-11/12 h-5/6 sm:w-7/12 sm:h-5/6 md:w-6/12   md:h-4/5  lg:w-4/12 lg:h-4/6 bg-black  rounded-lg border ">
{/* for x  */}
<div className="w-full min-h-7  flex justify-end items-center pr-3 text-white text-lg">
<p className='cursor-pointer' onClick={handleSkip}>x</p>
</div>
{/* for x  */}

{/* main component  */}
<div className="w-full h-full  rounded-lg p-2">
 {/* heading  */}
 <div className="w-full min-h-10  flex justify-center items-center ">

<h1 className="font-bold text-white text-[25px]">Watch Tutorial</h1>
 </div>
 {/* heading  */}


{/* vedio carrosal  */}
<div className="w-full h-3/5 p-5">
<div className="w-full h-5/6 rounded-md flex justify-between relative">
        {/* Prev Button */}
        <div onClick={prevVideo} className="cursor-pointer absolute left-0 top-1/2 transform -translate-y-1/2 min-w-10 -translate-x-1/2 bg-gray-700 bg-opacity-70 min-h-14 px-2 py-1 rounded-lg z-50 flex justify-center items-center">
          <p className="text-white font-mono inter-bold">{'<'}</p>
        </div>

        {/* Main Content (Video) */}
        <div className="w-full bg-red-100 rounded-xl border flex justify-center items-center">
          <video className="w-full h-full rounded-xl object-cover" controls ref={videoRef}>
            <source src={videos[currentVideo]} className='object-cover' type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>

        {/* Next Button */}
        <div onClick={nextVideo} className="cursor-pointer absolute right-0 top-1/2 transform -translate-y-1/2 min-w-10 translate-x-1/2 bg-gray-700 bg-opacity-70 px-2 py-1 rounded-lg min-h-14 z-50 flex justify-center items-center">
          <p className="text-white font-mono inter-bold">{'>'}</p>
        </div>
      </div>

      {/* Dots Indicator */}
      <div className="w-full h-10 flex justify-center items-center">
        {videos.map((_, index) => (
          <div
            key={index}
            className={`h-3 w-3 mx-2 rounded-full ${currentVideo === index ? 'bg-gray-800' : 'bg-gray-400'}`}
          />
        ))}
      </div>
    </div>
{/* vedio carrosal  */}


{/* text  */}
<div className="w-full min-h-36  p-2">
    {/* headding  */}
    <div className="w-full  min-h-9 flex justify-start  items-center ">
<p className="text-white text-[20px] inter-bold ">How to start your first virtual store? </p>
    </div>

    <div className='w-full h-full flex justify-center items-center overflow-y-auto'>
    {/* text  */}
<p className='text-white font-light text-[13px]'>
In this video, we’ll show you how to set up your first virtual store with ease. Follow our step-by-step guide to create, customize, and launch your store in minutes, no coding needed! Perfect for beginners looking to get started quickly.
</p>

</div>
</div>




</div>
{/* main component  */}


 </div>

</div>




        {/* <div className="w-full h-full rounded-lg "> */}
            {/* <div className="w-full min-h-5 flex justify-end pr-2 bg-red-300 "> */}
{/* <p>X</p> */}
            {/* </div> */}
            {/* <div> */}
                {/* padding  */}
                {/* <div> */}
                    {/* viedio  */}
                {/* </div> */}

                {/* <div> */}
                    {/* text  */}

                {/* </div> */}
            {/* </div> */}
        {/* </div> */}
        </>
    )
}

export default RetailerOnBoardingTutorial